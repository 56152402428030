<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Edit Utility Meter</p>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="meter.deviceName"
                label="Name"
                rules="required"
              ></validated-text-field
            ></v-col>

            <v-col sm="6">
              <validated-text-field
                v-model="meter.friendlyName"
                label="Display Name"
                rules="required"
              ></validated-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <validated-select-field
                :items="resourceTypes"
                item-value="id"
                item-text="resourceTypeName"
                rules="required"
                v-model="meter.resourceTypeId"
                label="Resource Type"
                hint="What type of meter is this"
                persistent-hint
                disabled
              ></validated-select-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6" class="mt-3">
              <site-location-field
                v-if="finishedLoading"
                :siteId="siteId"
                :selectedSiteLocationId="meter.siteLocationId"
                @sitelocationselected="siteLocationSelected"
              ></site-location-field>
            </v-col>

            <v-col sm="6">
              <v-row>
                <v-col sm="7">
                  <validated-select-field
                    :items="endUseTypesWithPercentages"
                    item-value="endUseTypeId"
                    item-text="endUseTypeWithPercentage"
                    rules=""
                    v-model="selectedEndUseType"
                    label="EndUseType"
                    hint="What end use type does this meter measure"
                    persistent-hint
                    @input="handleSetEndUseType($event)"
                  ></validated-select-field>
                </v-col>
                <v-col sm="5">
                  <v-text-field
                    v-model="selectedEndUseTypePercentage.percentage"
                    label="Percentage"
                    hint="Percent of end usage"
                    persistent-hint
                    type="number"
                    min="0"
                    max="100"
                    step="10"
                    ref="percentText"
                    @input="handleChangePercentValue"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        color="primary"
                        title="Set end use percentage"
                        @click="handleSetEndUseTypePercentage"
                      >
                        Set
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!isValidMeter">Save</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>

        <v-row justify="center">
            <stepper-validate 
            :invalid="invalid"
            :meterName="meter.deviceName"
            :friendlyName="meter.friendlyName"
            :siteLocation="selectedSiteLocation"
            :endUseTypesWithPercentages="endUseTypesWithPercentages"            
            @dismissdialog = "dismissDialog"
            @fixmeter = "fixMeterErrors"
            ref="stepper"></stepper-validate>
        </v-row>
      </v-form></validation-observer
    ></v-container
  >
</template>

<script>
import goTo from "vuetify/es5/services/goto";
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import SiteLocationField from "@/modules/site/site_location/_components/SiteLocation";
import StepperValidate from "./StepperValidate.vue"
import api from "../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField,
    "validation-observer": ValidationObserver,
    "site-location-field": SiteLocationField,
    "stepper-validate": StepperValidate,
  },

  data() {
    return {
      deviceSubTypeId: 1, //1 - UtilityMeter
      valid: true,
      meter: {
        siteId: null,
        deviceName: null,
        friendlyName: null,
        resourceTypeId: null,
        endUseTypePercentageResourceModels: [],
        siteLocationId: null,
      },
      deviceName: null,
      friendlyName: null,
      siteId: null,
      siteLocations: [],
      createEndUseTypePercentageResourceModels: [],
      resourceTypes: [],
      endUseTypes: [],
      endUseTypesWithPercentages: [],
      selectedSiteLocation: null,
      selectedResourceType: null,
      selectedEndUseType: null,
      selectedEndUseTypePercentage: {
        endUseTypeId: null,
        percentage: 0,
      },
      isValidMeter: true,
      finishedLoading: false,
      loading: true
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    try {
      await this.getLookups();
    } catch (error) {
      this.$toast.show("Cannot find locations and resource types.", null, "error");
    }
    this.meter = await api.getMeter(this.$route.params.meterId);
    await this.getCascadingLookups(this.meter);

    this.selectedEndUseType =
      this.meter.endUseTypePercentageResourceModels[0].endUseTypeId;

    await this.handleSetResourceType(this.meter.resourceTypeId);
    this.handleSetEndUseType(this.selectedEndUseType);
    this.finishedLoading = true;
  },

  methods: {

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }
      this.$refs.stepper.validateMeter();
    },

    async getLookups() {
      this.resourceTypes = await api.getResourceTypes(this.siteId);
    },

    async getCascadingLookups(meter) {
      this.endUseTypes = await api.getEndUseTypes(meter.resourceTypeId);
    },

    siteLocationSelected(selectedSiteLocation) {
      this.selectedSiteLocation = selectedSiteLocation;
    },

    async handleSetResourceType(resourceTypeId) {
      this.endUseTypes = await api.getEndUseTypes(resourceTypeId);
      this.endUseTypesWithPercentages = this.mapEndUseTypesPercentages(
        this.endUseTypes
      );
    },

    handleSetEndUseType(endUseTypeId) {
      this.selectedEndUseTypePercentage = this.endUseTypesWithPercentages.find(
        (p) => p.endUseTypeId === endUseTypeId
      );
    },

    handleChangePercentValue() {
      this.isValidMeter = false;
    },

    handleSetEndUseTypePercentage() {
      const self = this;

      const updatedPercentages = this.endUseTypesWithPercentages.map((p) =>
        p.endUseTypeId === self.selectedEndUseTypePercentage.endUseTypeId
          ? {
              ...p,
              percentage: self.selectedEndUseTypePercentage.percentage * 1,
              endUseTypeWithPercentage: `${p.endUseType} - (${self.selectedEndUseTypePercentage.percentage}%)`,
            }
          : p
      );
      this.endUseTypesWithPercentages = [...updatedPercentages];
      this.isValidMeter = true;
    },

    mapEndUseTypesPercentages(endUseTypes) {
      //const percentage = 0;
      const self = this;
      let mapped = endUseTypes.map((endUseType) => {
        let match =  self.meter.endUseTypePercentageResourceModels.find(
            (p) => p.endUseTypeId === endUseType.id
          )
        let percentage = match?.percentage * 100 || 0;
        return {
          endUseTypeId: endUseType.id,
          percentage: percentage,
          endUseType: endUseType.endUseType,
          endUseTypeWithPercentage: `${endUseType.endUseType} - (${percentage}%)`,
        };
      });
      return mapped;
    },

    mapCreateEndUseTypePercentageResourceModels() {
      const mapped = this.endUseTypesWithPercentages.map((eut) => {
        return {
          endUseTypeId: eut.endUseTypeId,
          percentage: eut.percentage / 100,
        };
      });
      return mapped;
    },

    //Fix button appears to focus DOM to correct input
    fixMeterErrors(item) {
      switch (item) {
        case 2:
          this.$nextTick().then(() => {
            this.$refs.siteLocation.$refs.siteLocation.focus();
          });
          break;
        case 3:
          this.$nextTick().then(() => {
             this.$refs.percentText.focus();
          });
          break;
        default:
          break;
      }
    },

    async dismissDialog(isValid) {
      if (isValid) {
        goTo(0); //scroll up
        this.loading = true;
        try {
          this.meter.siteId = this.siteId;
          this.meter.endUseTypePercentageResourceModels =
          this.mapCreateEndUseTypePercentageResourceModels();
          this.meter.siteLocationId = this.selectedSiteLocation?.id;
          await api.updateUtilityMeter(this.meter);
          this.$router.push({ name: "UtilityMeterIndex" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    handleCancelCrud() {
      this.$router.push({name: "UtilityMeterIndex"});
    }

  },
};
</script>